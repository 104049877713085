import React from 'react';
import * as Ink from '@carta/ink';
import Header from '../components/Header/Header';
import Content from '../components/Content/Content';
import Layout from '../components/Layout';

const NotFound = () => {
  return (
    <Layout pageTitle="Page Not Found">
      <Header title="Area 51" />
      <Content>
        <Ink.EmptyState type="page" icon="notfound" text="Page not found" />
      </Content>
    </Layout>
  );
};

export default NotFound;
